import { Injectable, Inject } from '@angular/core';

import { AppStore, AccountDataActions, AppAsyncAction, HttpAction, ActionStatus, AppAction, RequestStatus } from "../../state";
import { AccountDataController } from "./accountDataController";
import { AccountDataRequest, ApiResponse, AccountDataResponse } from "../../models";

@Injectable()
export class AccountDataActionCreator {

  constructor(
    @Inject(AppStore) protected appStore: AppStore,
    @Inject(AccountDataController) protected accountDataController: AccountDataController
  ) { }


  dispatchFetchEntities = (requestModel: AccountDataRequest) => this.appStore.dispatch(this.fetchEntities(requestModel)) as HttpAction<ApiResponse>;
  fetchEntities(requestModel: AccountDataRequest): HttpAction<ApiResponse> {

    return this.createHttpAction<ApiResponse>(

      AccountDataActions.ACCOUNT_FETCH_ENTITIES,

      (dispatch, state, callback) => {
        return this.accountDataController.getAccountData(requestModel).subscribe((accountDataResult) => {

          dispatch({ type: AccountDataActions.ACCOUNT_ENTITIES_LOADED, payload: accountDataResult });

          callback(new ActionStatus(true, accountDataResult));
        })
      }
    );
  }

  dispatchUserLogin = (accountRequest: AccountDataRequest) => this.appStore.dispatch(this.userLogin(accountRequest)) as HttpAction<ApiResponse>;
  userLogin(accountRequest: AccountDataRequest): HttpAction<ApiResponse> {
    return this.createHttpAction<ApiResponse>(

      AccountDataActions.USER_LOGGING_IN,

      (dispatch, state, callback) => {

        return this.accountDataController.login(accountRequest).subscribe((accountResponse) => {

          dispatch({ type: AccountDataActions.USER_LOGIN_RESULT, payload: accountResponse });

          // Update complete request status
          callback(new ActionStatus(true, accountResponse));
        })

      });
  }

  dispatchUserLogout = (accountRequest: AccountDataRequest) => this.appStore.dispatch(this.userLogout(accountRequest)) as HttpAction<ApiResponse>;
  userLogout(accountRequest: AccountDataRequest): HttpAction<ApiResponse> {
    return this.createHttpAction<ApiResponse>(

      AccountDataActions.USER_LOGGING_OUT,

      (dispatch, state, callback) => {

        return this.accountDataController.logout(accountRequest).subscribe((accountResponse) => {

          dispatch({ type: AccountDataActions.USER_LOGOUT_RESULT, payload: accountResponse });

          // Update complete request status
          callback(new ActionStatus(true, accountResponse));
        })

      });
  }

  dispatchAccountVerifyRequest = (accountRequest: AccountDataRequest, actionName: string, resultActionName: string) => this.appStore.dispatch(this.accountVerifyRequest(accountRequest, actionName, resultActionName)) as HttpAction<ApiResponse>;
  accountVerifyRequest(accountRequest: AccountDataRequest, actionName: string, resultActionName: string): HttpAction<ApiResponse> {
    return this.createHttpAction<ApiResponse>(

      actionName,

      (dispatch, state, callback) => {

        return this.accountDataController.postAccountRequest(accountRequest).subscribe((accountResponse) => {

          dispatch({ type: resultActionName, payload: accountResponse });

          // Update complete request status
          callback(new ActionStatus(true, accountResponse));
        })

      });
  }

  dispatchCreateAccount = (accountRequest: AccountDataRequest) => this.appStore.dispatch(this.createAccount(accountRequest)) as HttpAction<ApiResponse>;
  createAccount(accountRequest: AccountDataRequest): HttpAction<ApiResponse> {
    return this.createHttpAction<ApiResponse>(

      AccountDataActions.ACCOUNT_CREATE_ACCOUNT_REQUEST,

      (dispatch, state, callback) => {

        return this.accountDataController.postAccountRequest(accountRequest).subscribe((accountResponse) => {

          dispatch({ type: AccountDataActions.ACCOUNT_CREATE_ACCOUNT_REQUEST_RESULT, payload: accountResponse });

          // Update complete request status
          callback(new ActionStatus(true, accountResponse));
        })

      });
  }

  dispatchVerifyCode = (accountRequest: AccountDataRequest) => this.appStore.dispatch(this.verifyCode(accountRequest)) as HttpAction<ApiResponse>;
  verifyCode(accountRequest: AccountDataRequest): HttpAction<ApiResponse> {
    return this.createHttpAction<ApiResponse>(

      AccountDataActions.ACCOUNT_VERIFY_CODE,

      (dispatch, state, callback) => {

        return this.accountDataController.verifyCode(accountRequest).subscribe((accountResponse) => {

          dispatch({ type: AccountDataActions.ACCOUNT_VERIFY_CODE_RESULT, payload: accountResponse });

          // Update complete request status
          callback(new ActionStatus(true, accountResponse));
        })

      });
  }



  dispatchRequestLoggedInUser = () => this.appStore.dispatch(this.requestLoggedInUser()) as HttpAction<ApiResponse>;
  requestLoggedInUser(): HttpAction<ApiResponse> {
    return this.createHttpAction<ApiResponse>(

      AccountDataActions.REQUEST_LOGGED_IN_USER,

      (dispatch, state, callback) => {

        return this.accountDataController.getLoggedInUser().subscribe((apiResponse) => {

          dispatch({ type: AccountDataActions.LOGGED_IN_USER_LOADED, payload: apiResponse });

          // Update complete request status
          callback(new ActionStatus(true, apiResponse));
        },
          (error) => {
            callback(new ActionStatus(true, error, RequestStatus.ERROR));
          });
      });
  }

  dispatchChangeUserPassword = (accountRequest: AccountDataRequest) => this.appStore.dispatch(this.changeUserPassword(accountRequest)) as HttpAction<ApiResponse>;
  changeUserPassword(accountRequest: AccountDataRequest): HttpAction<ApiResponse> {
    return this.createHttpAction<ApiResponse>(

      AccountDataActions.USER_CHANGE_PASSWORD,

      (dispatch, state, callback) => {

        return this.accountDataController.changePassword(accountRequest).subscribe((accountResponse) => {

          dispatch({ type: AccountDataActions.USER_CHANGE_PASSWORD_RESULT, payload: accountResponse });

          // Update complete request status
          callback(new ActionStatus(true, accountResponse));
        })
      });
  }

  dispatchUpdateProfile = (accountRequest: AccountDataRequest) => this.appStore.dispatch(this.updateProfile(accountRequest)) as HttpAction<ApiResponse>;
  updateProfile(accountRequest: AccountDataRequest): HttpAction<ApiResponse> {
    return this.createHttpAction<ApiResponse>(

      AccountDataActions.USER_UPDATE_PROFILE,

      (dispatch, state, callback) => {

        return this.accountDataController.postAccountData(accountRequest).subscribe((accountResponse) => {

          dispatch({ type: AccountDataActions.USER_UPDATE_PROFILE_RESULT, payload: accountResponse });

          // Update complete request status
          callback(new ActionStatus(true, accountResponse));
        })
      });
  }

  dispatchUpdateUserSettings = (accountRequest: AccountDataRequest) => this.appStore.dispatch(this.updateUserSettings(accountRequest)) as HttpAction<ApiResponse>;
  updateUserSettings(accountRequest: AccountDataRequest): HttpAction<ApiResponse> {
    return this.createHttpAction<ApiResponse>(

      AccountDataActions.USER_UPDATE_SETTINGS,

      (dispatch, state, callback) => {

        return this.accountDataController.postAccountData(accountRequest).subscribe((accountResponse) => {

          dispatch({ type: AccountDataActions.USER_UPDATE_SETTINGS_RESULT, payload: accountResponse });

          // Update complete request status
          callback(new ActionStatus(true, accountResponse));
        })
      });
  }

  public createHttpAction<T>(actionType: any, executeAsync: any, payload: T = null): HttpAction<T> {

    let httpAction: HttpAction<T> = {
      type: actionType,
      executeAsync: executeAsync,
      payload: payload
    }

    return httpAction;
  }
}