import { AbstractConfDataCommand } from "./abstractConfDataCommand";
import { GridArgument } from "../arguments/gridArgument";
import { SearchArgument } from "../arguments/searchArgument";

export class DataSelectorCommand extends AbstractConfDataCommand {

  dataSelectorId: number;

  /** Removes any filtering applied to result */
  clearSearch?: boolean;

  key?: number;

  setKey?: number;

  clearKey?: boolean;

  gridData?: GridArgument;

  search?: SearchArgument;
}