import { Inject, Component, Input, Optional, ViewChild, ChangeDetectorRef } from "@angular/core";
import * as Immutable from "immutable";

import { BaseActionBoxComponent } from "../..";
import { ConfigurationSessionManager, ConfPageSessionService, ConfiguratorStore } from "../../../configurator/providers";
import { ConfRevision, ConfHistory, State } from "../../models";
import { StateItemComponent } from "./stateItemComponent";
import { GlobalDataStore } from "../../providers/globalData";
import { StateHistoryPopupComponent } from "./stateHistoryPopupComponent";
import { StateActionPopupComponent } from "./stateActionPopupComponent";
import { StateStore } from "./stateStore";
import { ManagedSubscription } from "../../../../shared/managedSubscription";
import { sleep, Delay } from "../../../../shared/utils";

@Component({
  selector: 'states-action-box',
  templateUrl: './statesActionBoxComponent.html',
  providers: [StateStore]
})
export class StatesActionBoxComponent extends BaseActionBoxComponent {
  public states: Array<StateModel>;

  public showHistoryButton = false;
  public enablePromote = true;
  public enableDemote = true;

  public showHistoryPopup: boolean = false;
  public showActionPopup: boolean = false;

  @ViewChild(StateHistoryPopupComponent)
  public historyPopup: StateHistoryPopupComponent;

  @ViewChild(StateActionPopupComponent)
  public actionPopup: StateActionPopupComponent;

  constructor(
    @Inject(ConfiguratorStore) public confStore: ConfiguratorStore,
    @Optional() @Inject(ConfPageSessionService) public storeSession: ConfPageSessionService,
    @Inject(StateStore) public stateStore: StateStore,
    @Inject(GlobalDataStore) public globalDataStore: GlobalDataStore,
    @Inject(ChangeDetectorRef) public cdr: ChangeDetectorRef
  ) {
    super(confStore);
  }

  ngOnInit() {
    super.ngOnInit();

    this.stateStore.onStateActionResult({
      next: (messages) => {
        // Handle result. Either update the states or show error.
        let message = messages.first();
        if (message.success)
          this.initialize();
        else {
          this.showActionPopup = true;
          sleep(Delay.MS_0).then(x => this.actionPopup.showError(message));
        }
      },
      listenNewEventsOnly: true
    }).unsubscribeOn(this.unsubscribeSubject);
  }

  public shouldBeVisible(): boolean {

    if (!this.conf || !this.conf.authorization.canViewState)
      return false;

    return this.states && this.states.length > 0;
  }

  public setup(): void {
    this.states = new Array<StateModel>();

    if (!this.conf || !this.conf.authorization.canViewState)
      return;

    // Get conf history and global states and list them.
    this.globalDataStore.getEntities<State>(State.name).subscribe((storeResponse) => {
      this.states = new Array<StateModel>();
      let sortedStates = storeResponse.data.sortBy(x => x.order); // sort by order  

      if (!this.storeSession || sortedStates.isEmpty())
        return;

      let activeState: State = sortedStates.get(this.conf.state.longId);

      let activeHistory = this.conf.histories.find(history => history.toState != null && history.toState.longId === this.conf.state.longId);

      // Need to know if we performed a promote or demote action.
      let promoted = activeHistory != null && sortedStates.find(x => activeHistory.toState.longId === x.longId).order > sortedStates.find(x => activeHistory.fromState.longId === x.longId).order;

      let count = sortedStates.count();
      let index = 0;
      sortedStates.forEach(state => {
        let historyTo = this.conf.histories.find(history => history.toState != null && history.toState.longId === state.longId);
        let historyFrom = this.conf.histories.find(history => history.fromState != null && history.fromState.longId === state.longId);
        let active = state.longId === this.conf.state.longId;

        let stateModel: StateModel = {
          title: state.name,
          icon: state.iconRelativeUrl,
          date: historyTo ? historyTo.creationDate : null,
          locked: historyTo != null || historyFrom != null, // state is locked if it hasnt been active (and not included in the history)
          active: active,
          lineTop: index < count - 1, // show top line if we're not at the end 
          lineBottom: index > 0, // show bottom line if we're not at the beginning

          /* active top line if:
             * promoted -> if we went FROM the current state,
             * demoted  -> if we went TO the current state */
          lineTopActive: activeHistory != null && (promoted ? activeHistory.fromState.longId === state.longId : activeHistory.toState.longId === state.longId),
          lineBottomActive: activeHistory != null && (promoted ? activeHistory.toState.longId === state.longId : activeHistory.fromState.longId === state.longId)
        }

        // Always insert at first position so the highest order will be first
        this.states.splice(0, 0, stateModel);
        index++;
      });

      // Check if we can promote/demote the active state.
      this.enablePromote = this.conf.authorization.canPromoteState && activeState.order < sortedStates.last().order;
      this.enableDemote = this.conf.authorization.canDemoteState && activeState.order > sortedStates.first().order;

      this.visible = this.shouldBeVisible(); //TODO should be handled in base in different way
      this.showHistoryButton = this.conf.authorization.canViewHistory && !this.conf.histories.isEmpty();

      this.cdr.markForCheck();
    }).unsubscribeOn(this.unsubscribeSubject);
  }

  public onPromoteClick(): void {
    this.showActionPopup = true;
    sleep(Delay.MS_0).then(x =>
    {      
      this.actionPopup.show(true);
    });
  }
  public onDemoteClick(): void {
    this.showActionPopup = true;
    sleep(Delay.MS_0).then(x => this.actionPopup.show(false));

  }

  public onPopupClosed() {
    this.showActionPopup = false;
    this.showHistoryPopup = false;
  }

  public onHistoryClick(): void {
    this.showHistoryPopup = true;
    sleep(Delay.MS_0).then(x => this.historyPopup.show());
  }
}

export class StateModel {
  title: string;
  icon: string;
  date: string;

  active: boolean;
  locked: boolean;

  lineTop: boolean;
  lineTopActive: boolean;
  lineBottom: boolean;
  lineBottomActive: boolean;
}