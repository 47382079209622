import { Component, Inject } from "@angular/core";
import { BaseComponent } from "../../shared";
import { GlobalDataStore } from "../../shared/providers/globalData";
import * as Immutable from "immutable";
import { HttpService } from "../../../shared/providers";
import { NotificationService } from "../../../shared/components";

@Component({
  selector: 'get-started',
  templateUrl: './getStartedComponent.html'
})
export class GetStartedComponent extends BaseComponent {
  public showGetStartedComponent: boolean = false;
  public helpFileUrl: string;
  public helpDocuments: Immutable.List<string>;

  constructor(
    @Inject(GlobalDataStore) public globalDataStore: GlobalDataStore,
    @Inject(HttpService) public httpService: HttpService
  ) {
    super();
  }

  ngOnInit() {
    this.helpDocuments = this.globalSettings.helpDocumentFilenames;

    if (this.helpDocuments != null && this.helpDocuments.size > 0) {
      this.showGetStartedComponent = true;
      this.helpFileUrl = this.helpDocuments.get(0);
    }
    else {
      this.showGetStartedComponent = false;
    }  

  }

  private get globalSettings(): any {
    return this.globalDataStore.getGlobalData().globalSettings;
  }

  public openHelpDocument(event): void {
    if (!this.helpFileUrl)
      return;

    this.fileExits(this.helpFileUrl).then(resolved => {
      if (resolved) 
        window.open(this.helpFileUrl, '_blank');
    });
  }

  // Search for file in folder
  private fileExits(url: string): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      let subscription = this.httpService.head(url, { observe: 'response' }).subscribe((response) => {
        resolve(response.status == 200);
        subscription.unsubscribe();
      }, (error) => {
        subscription.unsubscribe();
        return resolve(false);
      });
    });
  }

}