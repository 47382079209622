<!--  Show “Get started” section if there are at least one document classified as help.
      If only one document is classified as help link directly to the document.
      If more than one document is classified as help show a dialog that lists the help documents.
-->

<div *ngIf="showGetStartedComponent">
  <h1 class="text-uppercase start-header">{{strings.GetStarted}}</h1>
  <a class="py-2 d-flex" href="#" (click)="openHelpDocument($event)">{{strings.ReadHelpGuide}}</a>
</div>

